/* eslint-disable */

import React, { useState } from 'react'
import ReactMapGL from 'react-map-gl'
import { Popup as MapPopup } from 'react-map-gl'
import { updateViewport } from 'reducers/map'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import ConfigsModal from 'components/Map/ConfigsModal'
import SettingsModal from 'components/Map/SettingsModal'
import ObjectsLayer from 'components/Map/ObjectsLayer'
import PopupContent from 'components/Map/PopupContent'
import Legend from 'components/Map/Legend'
import Loader from '../components/Loader';
import { MAIN_CONFIG } from '../config'
import { isLocalhost } from 'serviceWorker'

const styles = {
  map: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  settingButton: {
    padding: 0,
    fontSize: '1.25rem',
    border: '1px solid',
  },
}

const Map = () => {
  const dispatch = useDispatch()
  const { viewport, trackedSource, displayedLayers } = useSelector(state => state.map)
  const [showConfig, setShowConfig] = useState(false)
  const [show, setShow] = useState(false)
  const [showPopupConfig, togglePopupConfig] = useState(false)
  const [showPopup, togglePopup] = useState(false)
  const [featureInfoHover, setFeatureInfoHover] = useState(undefined)

  const handleShowConfig = () => setShowConfig(true)
  const handleCloseConfig = () => setShowConfig(false)
  const popupCloseConfig = () => togglePopupConfig(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const popupClose = () => togglePopup(false)

  const onViewportChange = newViewport => {
    localStorage.setItem('newViewport', JSON.stringify(newViewport))
    dispatch(updateViewport(newViewport))
  }
  const layers = useSelector(state => state.chartis.layer_info)

  const onFeatureClick = e => {
    if (e?.features?.length > 0) {
      setFeatureInfoHover(e)
      togglePopup(true)
      console.log(e)
      setFeatureInfoHover(e)
    }
  }

  const configsModal = isLocalhost ? (
    <>
    <div className='btn-map-config'>
      <Button className='btn-rounded btn-rounded-white' style={styles.settingButton} onClick={handleShowConfig}>
        <i className='icons-admin' aria-hidden='true' />
      </Button>
    </div>
    <ConfigsModal show={showConfig} handleClose={handleCloseConfig} />
    </>
  ) : <div />

  return Object.keys(layers).length === 0 ? (
      <Loader />
  ) : (
    <main className='mastcontainer-map'>
      <div className='btn-map-setting'>
        <Button className='btn-rounded btn-rounded-white' style={styles.settingButton} onClick={handleShow}>
          <i className='icons-admin' aria-hidden='true' />
        </Button>
      </div>
      <SettingsModal show={show} handleClose={handleClose} popupClose={popupClose} />
      {configsModal}
      <ReactMapGL
        {...viewport}
        width={styles.map.width}
        height={styles.map.height}
        mapStyle={MAIN_CONFIG.mapStyleJSON}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        clickRadius={2} // Click made easier !
        id='chartis-viz-map'
        getCursor={() => styles.map.cursor}
      >
        {displayedLayers.length !== 0 && <ObjectsLayer />}
        {showPopup && (<MapPopup
          longitude={featureInfoHover?.lngLat[0]}
          latitude={featureInfoHover?.lngLat[1]}
          closeButton={true}
          captureScroll={true}
          onClose={() => togglePopup(false)}
          className='mapboxgl-hover-custom-popup'
        >{PopupContent({featureInfoHover, layers})}</MapPopup>)}
        <Legend />
      </ReactMapGL>
    </main>
  )
}

export default Map
