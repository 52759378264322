import config from 'config/config'

export const MAP_URL = config.proxy

export const DEFAULT_VIEWPORT = {
  latitude: 46.88731499073388,
  longitude: 2.5682289198122756,
  zoom: 5.6650,
  bearing: 0,
  pitch: 0,
}
