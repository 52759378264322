import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion, Button, Col, Form, Modal,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateDisplayedLayers, addLayerFilter } from 'reducers/map'
import Filter from './Filter'

const SettingsModal = ({ show, handleClose, popupClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { displayedLayers } = useSelector(state => state.map)
  const layer = useSelector(state => state.chartis.layer_info)
  const configuredLayers = useSelector(state => state.chartis.configuredLayers)

  const [showFilters, setShowFilters] = useState(undefined)

  const groupedLayers = Object.keys(configuredLayers).filter(key => configuredLayers[key].group).reduce((r, a) => {
    r[configuredLayers[a].group] = r[configuredLayers[a].group] || []
    r[configuredLayers[a].group].push(configuredLayers[a])
    return r
  }, Object.create(null))

  const onLayerSelect = object => {
    popupClose()
    if (!displayedLayers.find(obj => obj === object)) {
      dispatch(updateDisplayedLayers([...displayedLayers, object]))
    } else {
      dispatch(updateDisplayedLayers(displayedLayers.filter(obj => obj !== object)))
    }
  }

  const addFilter = () => {
    dispatch(addLayerFilter(layer[showFilters]))
  }

  const mapLayer = currentLayer => (
    <div key={currentLayer} className="row">
      <Form.Check
        type="switch"
        label={layer[currentLayer].description}
        id={currentLayer}
        onChange={() => onLayerSelect(currentLayer)}
        checked={displayedLayers.find(el => el === currentLayer)}
        key={currentLayer}
        className="mt-3 settings-check"
      />
      <Button
        size="sm"
        variant="link"
        style={{ marginTop: 'auto', marginLeft: 'auto' }}
        onClick={() => setShowFilters(currentLayer)}
      >
        <i className="icons-filters" aria-hidden="true" style={{ color: 'grey' }} />
      </Button>
    </div>
  )

  return (
    <>
      <div className={`settings-modal ${show ? 'active' : ''}`}>
        <div className="d-flex justify-content-between">
          <div className="modal-title">{t('Map.mapSettings')}</div>
          <Button
            className="settings-close-button"
            onClick={handleClose}
          >
            <i className="icons-close" aria-hidden="true" />
          </Button>
        </div>
        <Col className="mt-4 h3 p-0">{t('Map.objectsLayers')}</Col>
        <div className="dropdown-divider mb-2 w-100 ml-0" />

        <Col>
          <Accordion style={{ maxHeight: '80vh', overflow: 'overlay' }}>
            {Object.keys(groupedLayers).map(group => (
              <Accordion.Item key={group} eventKey={group}>
                <Accordion.Header>
                  {group}
                </Accordion.Header>
                <Accordion.Body>
                  {groupedLayers[group].map(currentLayer => mapLayer(currentLayer.name))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          {
            Object.keys(configuredLayers)
              .filter(key => configuredLayers[key].group === undefined)
              .map(currentLayer => mapLayer(currentLayer))
          }
        </Col>
        <Modal
          show={showFilters !== undefined}
          onHide={() => setShowFilters(undefined)}
          style={{ zIndex: 10000 }}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>{`${showFilters ? layer[showFilters].description : ''} - filtres`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showFilters ? (<Filter layer={layer[showFilters]} />) : (<></>)}
          </Modal.Body>
          <Modal.Footer style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Button variant="secondary" onClick={() => setShowFilters(undefined)}>
              Fermer
            </Button>
            <Button variant="primary" onClick={addFilter}>
              Ajouter un filtre
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

SettingsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  popupClose: PropTypes.func.isRequired,
}
export default SettingsModal
