import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Col } from 'react-bootstrap'
import { CONFIGS } from '../../config'

const ConfigsModal = ({ show, handleClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const handleClick = option => {
    const params = new URLSearchParams(window.location.search)
    params.set('config', option)
    history.push(`${window.location.pathname}?${params.toString()}`)
    window.location.reload(false)
  }

  return (
    <div className={`configs-modal ${show ? 'active' : ''}`}>
      <div className="d-flex justify-content-between">
        <div className="modal-title">{t('Map.mapSettings')}</div>
        <Button
          className="settings-close-button"
          onClick={handleClose}
        >
          <i className="icons-close" aria-hidden="true" />
        </Button>
      </div>
      <Col className="mt-4 h3 p-0">{t('Map.objectsConfigs')}</Col>
      <div className="dropdown-divider mb-2 w-100 ml-0" />
      <div className="dropdown-content">
        <ul className="list-unstyled">
          {Object.keys(CONFIGS).map(config => (
            <li key={config} style={{ marginBottom: 10 }}>
              <button type="button" key={config} onClick={() => handleClick(config)}>
                {config}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

ConfigsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}
export default ConfigsModal
