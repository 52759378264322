/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import "moment/locale/fr";

function PopupContent({featureInfoHover, layers}) {
	const properties = featureInfoHover.features[0].properties
	const layerId = featureInfoHover.features[0].layer['source-layer']

	return layerId in layers ? (
		<>
			<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
				<strong>Propriétés</strong>
			</div>
			<div className="mapboxgl-popup-container">
				{
					Object.keys(properties).map(property => (
							<div><strong>{layers[layerId].fields_details_mapped[property].description} : </strong>{properties[property]}</div>
						)
					)
				}
			</div>
		</>
	) : (<></>)
}


PopupContent.propTypes = {
	featureInfoHover: PropTypes.object,
}

export default PopupContent
