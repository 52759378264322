import React from 'react'
import { ReactComponent as WarningTriangle } from 'assets/warning_triangle.svg'

import './AccessDenied.scss'
import { MAIN_CONFIG } from './config'

export default function AccessDenied() {
  return (
    <div
      id="access-denied"
      className="d-flex w-100 justify-content-center align-items-center"
    >
      <div className="d-flex h-50 w-50 justify-content-center align-items-center text-center flex-column">
        <WarningTriangle className="warning-icon" />
        <span className="main-message">
          {MAIN_CONFIG.app_name}
        </span>
        <br />
        Merci de vous rapprocher du service compétent pour obtenir un accès
      </div>
    </div>
  )
}
