import { MAIN_API } from 'config/config'

const isTileserverResource = type => (type === 'Source' || type === 'Tile')

const replaceUrl = url => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

/**
 * Fonction de callback lors d'un requête vers une URL extérieure
 * @param {string} url - L'URL de la requête
 * @param {string} resourceType - Le type de ressource
 * @returns {(object| null)} Le token d'authentifcation lorsqu'il est nécessaire
 */
export const transformRequest = (url, resourceType) => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}
export const getSignalLayerId = type => `signal_${type.toLowerCase().replace(/ |\./g, '_')}`
