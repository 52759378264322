import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { auth } from '@osrdata/app_core'
import { PORTAL_URL } from 'config/config'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'
import { FaPowerOff } from 'react-icons/fa'

export default function TopBar({ appName }) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const main = useSelector(state => state.main)
  const { t } = useTranslation()
  const logout = () => {
    dispatch(auth.logout())
  }
  return (
    <div className={`mastheader${main.fullscreen ? ' fullscreen' : ''}`}>
      <div className="mastheader-logo">
        <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
      </div>
      <header role="banner" className="mastheader-title d-none d-xl-block">
        <h1 className="text-uppercase text-white pl-3 mb-0">{appName}</h1>
      </header>
      <ul className="mastheader-toolbar toolbar mb-0">
        <li className="toolbar-item separator-gray-500">
          <Dropdown>
            <Dropdown.Toggle variant="transparent">
              <i className="icons-menu-account icons-size-1x25 icons-md-size-1x5 mr-xl-2" aria-hidden="true" />
              <span className="d-none d-xl-block">
                {user.account.firstName}
                {' '}
                {user.account.lastName}
              </span>
              <i className="icons-arrow-down d-none d-xl-block" aria-hidden="true" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={logout} href="#">
                <span className="mr-2"><FaPowerOff /></span>
                {t('NavBar.disconnect')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  )
}

TopBar.propTypes = {
  appName: PropTypes.string.isRequired,
}
