import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import './Legend.css'

function Legend() {
  const chartisLayer = useSelector(state => state.chartis.layer_info)
  const configuredLayers = useSelector(state => state.chartis.configuredLayers)
  const displayedLayers = useSelector(state => state.map.displayedLayers)

  const [showLegend, setShowLegend] = useState(true)

  const layers = Object.entries(configuredLayers)
    .filter(layer => layer[1].legend && displayedLayers.includes(layer[0]))

  return showLegend && layers.length !== 0 ? (
    <div id="state-legend" className="legend">
      <div className="d-flex justify-content-between">
        <div className="modal-title" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '-5px' }}>
          Légende
        </div>
        <Button variant="link" onClick={() => setShowLegend(false)}>
          <i className="icons-circle-delete" aria-hidden="true" />
        </Button>
      </div>
      {layers.map(layer => (
        <div style={{ flexDirection: 'row' }} key={layer[0]}>
          <div>
            <b style={{ fontSize: '1.2em' }}>{chartisLayer[layer[0]].description}</b>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            {layer[1].legend.map(item => (
              <div key={item.category}>
                <span style={{ backgroundColor: item.color }} />
                {item.category}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  ) : <></>
}

export default Legend
