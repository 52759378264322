import React, { useEffect, Suspense, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import Loader from 'components/Loader'
import Home from 'Home'
import history from './history'
import 'App.scss'
import AccessDenied from './AccessDenied'
import { MAIN_CONFIG } from './config'

function App() {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector(state => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (MAIN_CONFIG.access_perm !== undefined && !appPermissions.includes(MAIN_CONFIG.access_perm)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  return accessDenied ? (<AccessDenied />) : (
    <>
      <Suspense fallback={<Loader center />}>
        {isLogged && (
        <Router history={history}>
          <Switch>
            <Route component={Home} path="/" />
          </Switch>
        </Router>
        )}
      </Suspense>
    </>
  )
}

export default App
