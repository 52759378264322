/* eslint-disable default-case */
import produce from 'immer'

export const GET_ERROR = 'GET_ERROR'

// Put all urls in this object
// const API_URLS = {
// }

const initialState = {
  error: null,
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ERROR:
        draft.error = action.error
        break
    }
  })
}
