/* eslint-disable default-case */
import { DEFAULT_VIEWPORT } from 'common/Map/const'
import produce from 'immer'
import { transformRequest } from 'utils/helpers'
import { MAIN_CONFIG } from '../config'

// Action Types
export const UPDATE_VIEWPORT = 'map/UPDATE_VIEWPORT'
export const UPDATE_DISPLAYED_LAYERS = 'map/UPDATE_DISPLAYED_LAYERS'
export const ADD_LAYER_FILTER = 'map/ADD_LAYER_FILTER'
export const UPDATE_LAYER_FILTER = 'map/UPDATE_LAYER_FILTER'
export const DELETE_LAYER_FILTER = 'map/DELETE_LAYER_FILTER'

const getDefaultLayer = () => {
  if (MAIN_CONFIG.all_layers) {
    return []
  }
  const layers = []
  Object.entries(MAIN_CONFIG.layers).forEach(value => {
    if (value[1].show_by_default) {
      layers.push(value[0])
    }
  })
  return layers
}

// Reducer
export const initialState = {
  viewport: {
    ...DEFAULT_VIEWPORT,
    transformRequest: (url, resourceType) => transformRequest(url, resourceType),
  },
  displayedLayers: getDefaultLayer(),
  layerFilters: {},
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_VIEWPORT:
        draft.viewport.width = action.viewport.width
        draft.viewport.height = action.viewport.height
        draft.viewport.latitude = action.viewport.latitude
        draft.viewport.longitude = action.viewport.longitude
        draft.viewport.zoom = action.viewport.zoom
        draft.viewport.bearing = action.viewport.bearing
        draft.viewport.pitch = action.viewport.pitch
        draft.viewport.transitionDuration = action.viewport.transitionDuration
        draft.viewport.transitionInterpolator = action.viewport.transitionInterpolator
        break
      case UPDATE_DISPLAYED_LAYERS:
        draft.displayedLayers = action.displayedLayers
        break
      case ADD_LAYER_FILTER:
        if (draft.layerFilters[action.layerSlug] === undefined) {
          draft.layerFilters[action.layerSlug] = {}
        }
        draft.layerFilters[action.layerSlug][action.filterIndex] = action.content
        break
      case UPDATE_LAYER_FILTER:
        draft.layerFilters[action.layerSlug][action.filterIndex] = action.content
        break
      case DELETE_LAYER_FILTER:
        delete draft.layerFilters[action.layerSlug][action.filterIndex]
        break
    }
  })
}

// Functions
export const updateViewport = viewport => dispatch => {
  dispatch({
    type: UPDATE_VIEWPORT,
    viewport,
  })
}

export const updateDisplayedConfigs = displayedLayers => dispatch => {
  dispatch({
    type: UPDATE_DISPLAYED_LAYERS,
    displayedLayers,
  })
}

export const addConfigFilter = layer => dispatch => {
  const filterIndex = (Math.random() + 1).toString(36).substring(8)
  dispatch({
    type: ADD_LAYER_FILTER,
    layerSlug: layer.name,
    filterIndex,
    content: {
      filterIndex,
      operator: 'eq',
      field: layer.fields[0],
    },
  })
}

export const updateDisplayedLayers = displayedLayers => dispatch => {
  dispatch({
    type: UPDATE_DISPLAYED_LAYERS,
    displayedLayers,
  })
}

export const addLayerFilter = layer => dispatch => {
  const filterIndex = (Math.random() + 1).toString(36).substring(8)
  dispatch({
    type: ADD_LAYER_FILTER,
    layerSlug: layer.name,
    filterIndex,
    content: {
      filterIndex,
      operator: 'eq',
      field: layer.fields[0],
    },
  })
}

export const updateLayerFilter = (layerSlug, layerFilter) => dispatch => {
  dispatch({
    type: UPDATE_LAYER_FILTER,
    layerSlug,
    filterIndex: layerFilter.filterIndex,
    content: layerFilter,
  })
}

export const deleteLayerFilter = (layerSlug, filterIndex) => dispatch => {
  dispatch({
    type: DELETE_LAYER_FILTER,
    layerSlug,
    filterIndex,
  })
}
