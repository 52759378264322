import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateLayerFilter, deleteLayerFilter } from '../../reducers/map'

const Filter = ({ layer }) => {
  const dispatch = useDispatch()
  const fields = layer.fields_details
  const filters = useSelector(state => state.map.layerFilters[layer.name]) || {}

  const onFieldChange = (e, filter) => {
    const newFilter = {
      ...filter,
      field: e.target.value,
    }
    dispatch(updateLayerFilter(layer.name, newFilter))
  }

  const onOperatorChange = (e, filter) => {
    const newFilter = {
      ...filter,
      operator: e.target.value,
    }
    dispatch(updateLayerFilter(layer.name, newFilter))
  }

  const onValueChange = (e, filter) => {
    const newFilter = {
      ...filter,
      value: e.target.value,
    }
    dispatch(updateLayerFilter(layer.name, newFilter))
  }

  const deleteFilter = filterIndex => {
    dispatch(deleteLayerFilter(layer.name, filterIndex))
  }

  return (
    <div>
      <Form>
        {Object.values(filters).map(filter => (
          <div className="form-check form-check-inline" style={{ width: '100%' }} key={filter.filterIndex}>
            <Button
              variant="link"
              style={{ marginTop: 'auto', marginLeft: 'auto' }}
              className="btn-only-icon"
              onClick={() => deleteFilter(filter.filterIndex)}
            >
              <i className="icons-circle-delete" aria-hidden="true" />
            </Button>
            <Form.Select onChange={e => onFieldChange(e, filter)} value={filter.field}>
              {fields.map(field => (
                <option key={field.name} value={field.name}>{field.description}</option>
              ))}
            </Form.Select>
            <Form.Select style={{ width: '30%' }} onChange={e => onOperatorChange(e, filter)} value={filter.operator}>
              <option value="eq">=</option>
              <option value="gt">{'>'}</option>
              <option value="gte">{'>='}</option>
              <option value="lt">{'<'}</option>
              <option value="lte">{'<='}</option>
              <option value="neq">!=</option>
            </Form.Select>
            <Form.Control
              type="text"
              placeholder="Valeur"
              style={{ width: '65%' }}
              onChange={e => onValueChange(e, filter)}
              value={filter.value}
            />
          </div>
        ))}
      </Form>
    </div>
  )
}

Filter.propTypes = {
  layer: PropTypes.object.isRequired,
}

export default Filter
