import React from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'

function ChartisLayer({
  mapUrl, sourceLayer, sourceTable, filters, next, layerProps,
}) {
  const mapProps = props => {
    const mappedProps = {
      ...props,
      /* eslint-disable react/prop-types */
      key: `${sourceTable}_${props.type}`,
      beforeId: next,
      id: `${sourceTable}_${props.type}`,
      'source-layer': sourceTable,
    }
    return mappedProps
  }

  const mapFilters = () => {
    if (filters === undefined) {
      return ''
    }
    const mappedFilter = Object.values(filters).map(filter => {
      if (filter.value === undefined) {
        return undefined
      }
      if (filter.operator === 'eq') {
        return `${filter.field}=${encodeURI(filter.value)}`
      }
      return `${filter.field}__${filter.operator}=${encodeURI(filter.value)}`
    })
    return mappedFilter.join('&')
  }

  return (
    <Source
      id={`${sourceTable}-${sourceLayer}-source`}
      type="vector"
      url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/${sourceLayer}/?${mapFilters()}`}
    >
      {Array.isArray(layerProps) ? layerProps.map(props => (
        // key in mapProps
        /* eslint-disable react/jsx-key */
        <Layer {...mapProps(props)} />
      )) : (
        <Layer {...mapProps(layerProps)} />
      )}
    </Source>
  )
}

ChartisLayer.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
  layerProps: PropTypes.any.isRequired,
  filters: PropTypes.array,
  next: PropTypes.string,
}

ChartisLayer.defaultProps = {
  next: undefined,
  filters: undefined,
}

export default ChartisLayer
